import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { EventsService } from './events.service';
import { Observable } from 'rxjs';
import { Token } from '../interfaces/token';

@Injectable()
export class AuthenticationService {

  loggedinHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private eventsService: EventsService,
  ) {
    const userSession = this.getFromSession();
    // console.log('LOGIN FROM SESSION', userSession);
    if (userSession !== null) {
      this.eventsService.changeUserData(userSession);
      this.setHeaders(userSession.access_token);
    }
  }

  setHeaders(access_token = null): void {
    this.loggedinHeaders = null;

    if (access_token) {
      this.loggedinHeaders = new HttpHeaders({
        'Authorization': `Bearer ${access_token}`
      });
    }
  }

  getHeaders = () => this.loggedinHeaders;

  saveSession(userDetails): any {
    try {
      localStorage.setItem('Stipes_Auth', JSON.stringify(userDetails));
      return true;
    } catch (e) {
      throw e;
    }
  }

  destroySession(): any {
    try {
      localStorage.removeItem('Stipes_Auth');
      return true;
    } catch (e) {
      throw e;
    }
  }

  getFromSession(): any {
    return JSON.parse(localStorage.getItem('Stipes_Auth'));
  }

  login(): void {
    window.location.href = `${ environment.authUrl }/${ environment.tenantId }/login`;
  }

  logout(): void {
    this.destroySession();
    window.location.href = `${ environment.authUrl }/${ environment.tenantId }/logout`;
  }

  retrieveToken(exchangeToken: string): any {
    return this.http.post(`${environment.apiBase}/auth/retrieve-token`, { 'exchange_token': exchangeToken });
  }

  refresh(refreshToken: string): Observable<Token> {
    return this.http.post<Token>(`${environment.apiBase}/auth/refresh-token`, { refresh_token: refreshToken });
  }
}
