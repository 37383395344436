<div class="container-fluid">
  <div class="row">
    <div class="col col-md-4 offset-md-4">
      <img src="assets/brand_black.svg" class="d-block ml-auto mr-auto">
      <h1 class="text-center font-weight-bold mt-3 mb-3">STEWARDS</h1>
    </div>
  </div>
  <div class="row">
    <div class="col col-md-4 offset-md-4 mt-4">
      <button type="button" (click)="login()" class="btn btn-primary btn-block">Sign in</button>
    </div>
  </div>
  <div class="row">
    <div class="col col-md-4 offset-md-4">
      <div class="text-center" style="padding-top: 10px;">Release: Purple</div>
    </div>
  </div>
</div>
