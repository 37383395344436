import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from '../../services/authentication.service';
import { EventsService } from '../../services/events.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: [
    './auth-callback.component.scss'
  ]
})
export class AuthCallbackComponent implements OnInit {
  public isLoading: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private eventsService: EventsService,
    private notification: ToastrService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const exchangeToken = params['exchange_token'];

      if (exchangeToken) {
        this.authService.retrieveToken(exchangeToken).subscribe(
          res => {
            this.authService.saveSession(res);
            this.authService.setHeaders(res.access_token);
            this.eventsService.changeUserData(res);
            this.router.navigate(['/fixtures']);
          },
          err => {
            console.error(err);
            this.notification.error(err.error.message, 'Authentication');
          }
        );
      } else {
        this.router.navigate(['/login']);
      }
    });
  }
}
