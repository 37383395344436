/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth-callback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./auth-callback.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/authentication.service";
import * as i6 from "../../services/events.service";
import * as i7 from "ngx-toastr";
var styles_AuthCallbackComponent = [i0.styles];
var RenderType_AuthCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthCallbackComponent, data: {} });
export { RenderType_AuthCallbackComponent as RenderType_AuthCallbackComponent };
function View_AuthCallbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "min-h-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "spinner"], ["class", "d-block ml-auto mr-auto"], ["src", "assets/loader.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading...\n"]))], null, null); }
export function View_AuthCallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AuthCallbackComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.isLoading)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AuthCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth-callback", [], null, null, null, View_AuthCallbackComponent_0, RenderType_AuthCallbackComponent)), i1.ɵdid(1, 114688, null, 0, i3.AuthCallbackComponent, [i4.Router, i4.ActivatedRoute, i5.AuthenticationService, i6.EventsService, i7.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthCallbackComponentNgFactory = i1.ɵccf("app-auth-callback", i3.AuthCallbackComponent, View_AuthCallbackComponent_Host_0, {}, {}, []);
export { AuthCallbackComponentNgFactory as AuthCallbackComponentNgFactory };
