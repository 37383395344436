import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../services/events.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  meta: {
    loading: boolean
    isLoggedIn: Object|Boolean
    urlReturn: string
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notification: ToastrService,
    private eventsService: EventsService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.meta = {
      loading: false,
      isLoggedIn: false,
      urlReturn: '/fixtures'
    };

    this.route.queryParams.subscribe(params => {
      this.meta.urlReturn = params['return'];
    });
  }

  login(): void {
    this.authService.login();
  }
}
