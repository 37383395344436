<form #scaleForm="ngForm">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title">VOID RACE</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col text-center">
        <p>You're going to Void this race.<br>Please confirm this decision.</p>
        <p class="text-danger">This action cannot be reverted.</p>
      </div>
    </div>
  </div>
  <div *ngIf="!meta.saving" class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-outline-dark" (click)="cancel('cancel')">Close</button>
    <button type="button" class="btn btn-primary" (click)="confirm()">Confirm</button>
  </div>
  <div *ngIf="meta.saving" class="modal-footer d-flex justify-content-around">
    <h1>
      <fa name="circle-o-notch" animation="spin"></fa>
    </h1>
  </div>
</form>
