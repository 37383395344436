<app-navbar></app-navbar>

<div class="container print-hide">
  <div class="row">
    <div *ngIf="!meta.loading" class="d-flex flex-column col-8">
      <h4>End of Day Report</h4>
    </div>
    <div class="d-flex flex-column col-4 justify-content-center" [ngClass]="{'offset-4': meta.loading}">
      <button type="button" class="btn btn-primary my-1" [disabled]="meta.loading" (click)="doPrint()">
        <span *ngIf="!meta.loading">Print End of Day Report</span>
        <span *ngIf="meta.loading">
          <fa name="circle-o-notch" animation="spin"></fa>
          <small>&nbsp;{{meta.status}}</small>
        </span>
      </button>
      <button type="button" class="btn btn-secondary my-1" [routerLink]="['/races', fixture.fixtureYear, fixture.fixtureId]">
        <span>Back to the Fixture</span>
      </button>
    </div>
  </div>
</div>

<!-- WEB VERSION -->
<div *ngIf="!meta.loading" class="scrollable-content" [ngClass]="{'print-hide': meta.printMode}">
  <div class="container mt-4">
    <!-- TITLE -->
    <div class="row">
      <div class="col text-left">
        <h4>{{fixture.courseName}} - {{fixture.fixtureDate | date:'EEEE, d of MMMM, y'}}</h4>
        <hr>
      </div>
    </div>
    <!-- OFFICIALS -->
    <div class="row">
      <div [ngClass]="{'col-8': fixture.officials.length, 'col-12': !fixture.officials.length}">
        <h5 class="mb-4">SCHEDULED OFFICIALS</h5>
        <div class="row mb-4">
          <ng-container *ngIf="fixture.scheduledOfficials['Chairman']">
            <div class="col-4">Chairman</div>
            <div class="col-8 d-flex flex-column justify-content-center">
              <span *ngFor="let item of fixture.scheduledOfficials['Chairman']">{{item.knownAs}}</span>
              <span *ngIf="!fixture.scheduledOfficials['Chairman']"> -</span>
            </div>
          </ng-container>
          <ng-container *ngIf="fixture.scheduledOfficials['Stewards\u2019 Panel Chair']">
            <div class="col-4">Stewards' Panel Chair</div>
            <div class="col-8 d-flex flex-column justify-content-center">
              <span *ngFor="let item of fixture.scheduledOfficials['Stewards\u2019 Panel Chair']">{{item.knownAs}}</span>
              <span *ngIf="!fixture.scheduledOfficials['Stewards\u2019 Panel Chair']"> -</span>
            </div>
          </ng-container>
        </div>
        <div class="row mb-4">
          <!-- Stipendiary Steward, BHA Steward, Steward -->
          <div class="col-4">Stewards</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <ng-container *ngIf="fixture.scheduledOfficials['Stipendiary Steward']">
                <span *ngFor="let item of fixture.scheduledOfficials['Stipendiary Steward']">{{item.knownAs}}</span>
                <span *ngIf="!fixture.scheduledOfficials['Stipendiary Steward']"> -</span>
            </ng-container>
            <ng-container *ngIf="fixture.scheduledOfficials['BHA Steward']">
                <span *ngFor="let item of fixture.scheduledOfficials['BHA Steward']">{{item.knownAs}}</span>
                <span *ngIf="!fixture.scheduledOfficials['BHA Steward']"> -</span>
            </ng-container>
            <ng-container *ngIf="fixture.scheduledOfficials['Steward']">
                <span *ngFor="let item of fixture.scheduledOfficials['Steward']">{{item.knownAs}}</span>
                <span *ngIf="!fixture.scheduledOfficials['Steward']"> -</span>
            </ng-container>
          </div>
        </div>
        <div class="row mb-4">
          <!-- Steward's Secretary, Assistant Steward -->
          <div class="col-4">Assistant Steward</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <ng-container *ngIf="fixture.scheduledOfficials['Steward\'s Secretary']">
              <span *ngFor="let item of fixture.scheduledOfficials['Steward\'s Secretary']">{{item.knownAs}}</span>
              <span *ngIf="!fixture.scheduledOfficials['Steward\'s Secretary']"> -</span>
            </ng-container>
            <ng-container *ngIf="fixture.scheduledOfficials['Assistant Steward']">
              <span *ngFor="let item of fixture.scheduledOfficials['Assistant Steward']">{{item.knownAs}}</span>
              <span *ngIf="!fixture.scheduledOfficials['Assistant Steward']"> -</span>
            </ng-container>
          </div>
        </div>
        
        <div class="row mb-4">
          <div class="col-4">Raceday Assistant</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Raceday Assistant']">{{item.knownAs}}</span>
            <span *ngIf="!fixture.scheduledOfficials['Raceday Assistant']"> -</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Clerk of Scales</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Clerk of Scales']">{{item.knownAs}}</span>
            <span *ngIf="!fixture.scheduledOfficials['Clerk of Scales']"> -</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Judge</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Judge']">{{item.knownAs}}</span>
            <span *ngIf="!fixture.scheduledOfficials['Judge']"> -</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Starter</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Starter']">{{item.knownAs}}</span>
            <span *ngIf="!fixture.scheduledOfficials['Starter']"> -</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Handicapper</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Handicapper']">{{item.knownAs}}</span>
            <span *ngIf="!fixture.scheduledOfficials['Handicapper']"> -</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Veterinary Officer</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Veterinary Officer']">{{item.knownAs}}</span>
            <span *ngIf="!fixture.scheduledOfficials['Veterinary Officer']"> -</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Equine Welfare Integrity Officer</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Equine Welfare Integrity Officer']">{{item.knownAs}}</span>
            <span *ngIf="!fixture.scheduledOfficials['Equine Welfare Integrity Officer']"> -</span>
          </div>
        </div>
      </div>
      <div class="col-4" *ngIf="fixture.officials">
        <h5 class="mb-4">UPDATED OFFICIALS</h5>
        <div class="row mb-4">
          <div class="col-12">
            <ul class="no-bullet">
              <li class="pb-1" *ngFor="let official of fixture.officials">{{official}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- GOING -->
    <hr>
    <h5 class="mb-4">GOING</h5>
    <div class="row mb-4">
      <div class="col-12">
        <span>{{fixture.goingText || 'Unknown'}}</span>
      </div>
    </div>
    <!-- FIXTURE COMMENT -->
    <hr>
    <h5 class="mb-4">FIXTURE COMMENTS</h5>
    <div class="page-break-after">
      <div class="row no-break-inside">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-1">Date/Time</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody *ngIf="fixture.comments.length">
              <tr *ngFor="let entry of fixture.comments">
                <td><small>{{entry.updatedAt}}</small></td>
                <td><small>{{entry.comment}}</small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!fixture.comments.length">
              <tr class="text-center mt-2">
                <td colspan="2">No comments for this fixture</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <h5 class="mb-4">REQUESTED DELAYS</h5>
    <div class="row">
      <div class="col-12">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col-1">Race number</th>
              <th scope="col-3">Race time</th>
              <th scope="col-1">Race delayed</th>
              <th scope="col">Requested delay time</th>
            </tr>
          </thead>
          <tbody *ngIf="fixture.raceDelays.length">
            <tr *ngFor="let raceDelay of fixture.raceDelays">
              <td>{{ raceDelay.raceNumber }}</td>
              <td>{{ raceDelay.publishedRaceTime }}</td>
              <td>+{{ raceDelay.inMinutes }} minutes</td>
              <td>{{ raceDelay.requestedDelayTime }}</td>
            </tr>
          </tbody>
          <tbody *ngIf="!fixture.raceDelays.length">
            <tr class="text-center mt-2">
              <td colspan="4">No race delays in this fixture</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- RACES -->
    <div *ngFor="let race of races" class="mt-5">
      <div class="webview-divider mb-3"></div>
      <!-- RACE NAME -->
      <div class="row">
        <div class="col-12">
          <h5>{{race.raceDateTime | date:'h:mm'}} {{race.name}}</h5>
          <hr>
        </div>
      </div>
      <!-- Non Runners -->
      <h5>Non Runners</h5>
      <div class="row">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-3">Horse</th>
                <th scope="col">Declared Reason</th>
                <th scope="col">Stipes Reason</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody *ngIf="race.nonRunners.length">
              <tr *ngFor="let entry of race.nonRunners">
                <td><small>{{entry.horseName}}</small></td>
                <td><small>{{entry.nonRunnerDeclaredReason}}</small></td>
                <td><small>{{entry.stipesReason}}</small></td>
                <td><small>{{entry.stipesComment}}</small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.nonRunners.length">
              <tr class="text-center mt-2">
                <td colspan="4">No non-runners for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Withdrawals -->
      <h5>Withdrawals</h5>
      <div class="row">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-3">Horse</th>
                <th scope="col-1">Fee Paid</th>
                <th scope="col">Reason</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody *ngIf="race.withdrawals.length">
              <tr *ngFor="let entry of race.withdrawals">
                <td><small>{{entry.horseName}}</small></td>
                <td><small>{{entry.feePaid ? 'Yes' : 'No'}}</small></td>
                <td><small>{{entry.stipesReason}}</small></td>
                <td><small>{{entry.stipesComment}}</small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.withdrawals.length">
              <tr class="text-center mt-2">
                <td colspan="4">No withdrawals for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Jockey Changes -->
      <h5>Jockey Changes</h5>
      <div class="row">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-3">Horse</th>
                <th scope="col-3">Old Jockey</th>
                <th scope="col-3">New Jockey</th>
                <th scope="col">Reason</th>
              </tr>
            </thead>
            <tbody *ngIf="race.jockeyChanged.length">
              <tr *ngFor="let jc of race.jockeyChanged">
                <td><small>{{jc.horseName}}</small></td>
                <td><small>{{jc.oldJockeyName}}</small></td>
                <td><small>{{jc.newJockeyName}}</small></td>
                <td><small>{{jc.jcReason}}</small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.jockeyChanged.length">
              <tr class="text-center mt-2">
                <td colspan="4">No jockey changes for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Race Comments -->
      <h5>Race Comments</h5>
      <div class="row">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-2">Date/Time</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody *ngIf="race.comments.length">
              <tr *ngFor="let entry of race.comments">
                <td><small>{{entry.updatedAt | date:'h:mm a'}}</small></td>
                <td><small>{{entry.comment}}</small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.comments.length">
              <tr class="text-center mt-2">
                <td colspan="2">No comments for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Horses Comments -->
      <h5>Horses Comments</h5>
      <div class="row">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-1">Time</th>
                <th scope="col-3">Applies To</th>
                <th scope="col-1">Type</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody *ngIf="race.horsesComments.length">
              <tr *ngFor="let comment of race.horsesComments">
                <td><small>{{comment.updatedAt | date:'h:mm a'}}</small></td>
                <td><small>{{comment.subject.knownAs}}</small></td>
                <td><small>{{comment.rpcComments === null ? 'Comment' : 'RPC'}}</small></td>
                <td>
                  <small>
                    {{comment.comment}}
                    <span *ngFor="let rpc of comment.rpcComments; last as last">
                      {{rpc.racePerformanceComment}}
                      <span *ngIf="!last">, </span>
                    </span>
                  </small>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.horsesComments.length">
              <tr class="text-center mt-2">
                <td colspan="4">No comments for horses in this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Race Enquiries -->
      <h5>Race Enquiries</h5>
      <div class="row">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-2">Offender</th>
                <th scope="col-2">Outcome</th>
                <th scope="col-6">Comment</th>
                <th scope="col-2">Penalties</th>
              </tr>
            </thead>
            <tbody *ngIf="race.enquiries.length">
              <tr *ngFor="let entry of race.enquiries">
                <td><small>{{entry.offender.knownAs}}</small></td>
                <td><small>{{entry.offence.offence}} {{entry.offence.subOffence}}</small></td>
                <td><small>{{entry.ptcComment}}</small></td>
                <td><small>{{entry.breachStatus | titlecase}}<br *ngIf="entry.breachStatus === 'in breach'"><app-enquiry-outcomes [enquiry]="entry"></app-enquiry-outcomes></small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.enquiries.length">
              <tr class="text-center mt-2">
                <td colspan="4">No enquiries for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Selected for Sampling -->
      <h5>Selected for Sampling</h5>
      <div class="row">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">Horse</th>
                <th scope="col">Reason</th>
              </tr>
            </thead>
            <tbody *ngIf="race.selectedSampling.length">
              <tr *ngFor="let entry of race.selectedSampling">
                <td><small>{{entry.horseName}}</small></td>
                <td>
                  <small>
                    <span *ngFor="let item of entry.sampleRequestReasons; last as last">
                      {{item.reason}}
                      <span *ngIf="!last">, </span>
                    </span>
                  </small>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.selectedSampling.length">
              <tr class="text-center mt-2">
                <td colspan="2">No horse selected for sampling for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- PRINT VERSION -->
<div *ngIf="!meta.loading" class="print-visible">
  <div class="container-fluid">
    <!-- TITLE -->
    <div class="row">
      <div class="col text-center">
        <h4>End of Day Report</h4>
        <h4>{{fixture.courseName}} - {{fixture.fixtureDate | date:'EEEE, d of MMMM, y'}}</h4>
        <hr>
      </div>
    </div>
    <!-- OFFICIALS -->
    <div class="row">
      <div [ngClass]="{'col-8': fixture.officials.length, 'col-12': !fixture.officials.length}">
        <h5 class="mb-4">SCHEDULED OFFICIALS</h5>
        <div class="row mb-4">
          <ng-container *ngIf="fixture.scheduledOfficials['Chairman']">
            <div class="col-4">Chairman</div>
            <div class="col-8 d-flex flex-column justify-content-center">
              <span *ngFor="let item of fixture.scheduledOfficials['Chairman']">{{item.knownAs}}</span>
            </div>
          </ng-container>          
          <ng-container *ngIf="fixture.scheduledOfficials['Stewards\u2019 Panel Chair']">
            <div class="col-4">Stewards' Panel Chair</div>
            <div class="col-8 d-flex flex-column justify-content-center">
              <span *ngFor="let item of fixture.scheduledOfficials['Stewards\u2019 Panel Chair']">{{item.knownAs}}</span>
            </div>
          </ng-container>
        </div>
        <div class="row mb-4">
          <!-- Stipendiary Steward, BHA Steward, Steward -->
          <div class="col-4">Stewards</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <ng-container *ngIf="fixture.scheduledOfficials['Stipendiary Steward']">
                <span *ngFor="let item of fixture.scheduledOfficials['Stipendiary Steward']">{{item.knownAs}}</span>
            </ng-container>
            <ng-container *ngIf="fixture.scheduledOfficials['BHA Steward']">
                <span *ngFor="let item of fixture.scheduledOfficials['BHA Steward']">{{item.knownAs}}</span>
            </ng-container>
            <ng-container *ngIf="fixture.scheduledOfficials['Steward']">
                <span *ngFor="let item of fixture.scheduledOfficials['Steward']">{{item.knownAs}}</span>
            </ng-container>
          </div>
        </div>
        <div class="row mb-4">
          <!-- Steward's Secretary, Assistant Steward -->
          <div class="col-4">Assistant Steward</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <ng-container *ngIf="fixture.scheduledOfficials['Steward\'s Secretary']">
              <span *ngFor="let item of fixture.scheduledOfficials['Steward\'s Secretary']">{{item.knownAs}}</span>
            </ng-container>
            <ng-container *ngIf="fixture.scheduledOfficials['Assistant Steward']">
              <span *ngFor="let item of fixture.scheduledOfficials['Assistant Steward']">{{item.knownAs}}</span>
            </ng-container>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Raceday Assistant</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Raceday Assistant']">{{item.knownAs}}</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Clerk of Scales</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Clerk of Scales']">{{item.knownAs}}</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Judge</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Judge']">{{item.knownAs}}</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Starter</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Starter']">{{item.knownAs}}</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Handicapper</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Handicapper']">{{item.knownAs}}</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Veterinary Officer</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Veterinary Officer']">{{item.knownAs}}</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-4">Equine Welfare Integrity Officer</div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <span *ngFor="let item of fixture.scheduledOfficials['Equine Welfare Integrity Officer']">{{item.knownAs}}</span>
          </div>
        </div>
      </div>
      <div class="col-4" *ngIf="fixture.officials.length">
        <h5 class="mb-4">UPDATED OFFICIALS</h5>
        <div class="row mb-4">
          <div class="col-12">
            <ul class="no-bullet">
              <li class="pb-1" *ngFor="let official of fixture.officials">{{official}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- GOING -->
    <hr>
    <h5 class="mb-4">GOING</h5>
    <div class="row mb-4">
      <div class="col-12">
        <span>{{fixture.goingText || 'Unknown'}}</span>
      </div>
    </div>
    <!-- FIXTURE COMMENT -->
    <hr>
    <h5 class="mb-4">FIXTURE COMMENTS</h5>
    <div class="row no-break-inside">
      <div class="col-12">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col-1">Date/Time</th>
              <th scope="col">Comment</th>
            </tr>
          </thead>
          <tbody *ngIf="fixture.comments.length">
            <tr *ngFor="let entry of fixture.comments">
              <td><small>{{entry.updatedAt}}</small></td>
              <td><small>{{entry.comment}}</small></td>
            </tr>
          </tbody>
          <tbody *ngIf="!fixture.comments.length">
            <tr class="text-center mt-2">
              <td colspan="2">No comments for this fixture</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <h5 class="mb-4">REQUESTED DELAYS</h5>
    <div class="page-break-after">
      <div class="row no-break-inside">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-1">Race number</th>
                <th scope="col-3">Race time</th>
                <th scope="col-1">Race delayed</th>
                <th scope="col">Requested delay time</th>
              </tr>
            </thead>
            <tbody *ngIf="fixture.raceDelays.length">
              <tr *ngFor="let raceDelay of fixture.raceDelays">
                <td>{{ raceDelay.raceNumber }}</td>
                <td>{{ raceDelay.publishedRaceTime }}</td>
                <td>+{{ raceDelay.inMinutes }} minutes</td>
                <td>{{ raceDelay.requestedDelayTime }}</td>
              </tr>
            </tbody>
            <tbody *ngIf="!fixture.raceDelays.length">
              <tr class="text-center mt-2">
                <td colspan="4">No race delays in this fixture</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- RACES -->
    <div *ngFor="let race of races; last as last" [ngClass]="{'page-break-after': !last}">
      <!-- RACE NAME -->
      <div class="row no-break-inside">
        <div class="col-12">
          <h5>{{race.raceDateTime | date:'h:mm'}} {{race.name}}</h5>
          <hr>
        </div>
      </div>
      <!-- Non Runners -->
      <h5>Non Runners</h5>
      <div class="row no-break-inside">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-3">Horse</th>
                <th scope="col">Declared Reason</th>
                <th scope="col">Stipes Reason</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody *ngIf="race.nonRunners.length">
              <tr *ngFor="let entry of race.nonRunners">
                <td><small>{{entry.horseName}}</small></td>
                <td><small>{{entry.nonRunnerDeclaredReason}}</small></td>
                <td><small>{{entry.stipesReason}}</small></td>
                <td><small>{{entry.stipesComment}}</small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.nonRunners.length">
              <tr class="text-center mt-2">
                <td colspan="4">No non-runners for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Withdrawals -->
      <h5>Withdrawals</h5>
      <div class="row no-break-inside">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-3">Horse</th>
                <th scope="col-1">Fee Paid</th>
                <th scope="col">Reason</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody *ngIf="race.withdrawals.length">
              <tr *ngFor="let entry of race.withdrawals">
                <td><small>{{entry.horseName}}</small></td>
                <td><small>{{entry.feePaid ? 'Yes' : 'No'}}</small></td>
                <td><small>{{entry.stipesReason}}</small></td>
                <td><small>{{entry.stipesComment}}</small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.withdrawals.length">
              <tr class="text-center mt-2">
                <td colspan="4">No withdrawals for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Jockey Changes -->
      <h5>Jockey Changes</h5>
      <div class="row no-break-inside">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-3">Horse</th>
                <th scope="col-3">Old Jockey</th>
                <th scope="col-3">New Jockey</th>
                <th scope="col">Reason</th>
              </tr>
            </thead>
            <tbody *ngIf="race.jockeyChanged.length">
              <tr *ngFor="let entry of race.jockeyChanged">
                <td><small>{{entry.horseName}}</small></td>
                <td><small>{{entry.oldJockeyName}}</small></td>
                <td><small>{{entry.newJockeyName}}</small></td>
                <td><small>{{entry.stipesReason}}</small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.jockeyChanged.length">
              <tr class="text-center mt-2">
                <td colspan="4">No jockey changes for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Race Comments -->
      <h5>Race Comments</h5>
      <div class="row no-break-inside">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-2">Time</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody *ngIf="race.comments.length">
              <tr *ngFor="let entry of race.comments">
                <td><small>{{entry.updatedAt | date:'h:mm a'}}</small></td>
                <td><small>{{entry.comment}}</small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.comments.length">
              <tr class="text-center mt-2">
                <td colspan="2">No comments for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Horses Comments -->
      <h5>Horses Comments</h5>
      <div class="row no-break-inside">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-1">Time</th>
                <th scope="col-3">Applies To</th>
                <th scope="col-1">Type</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody *ngIf="race.horsesComments.length">
              <tr *ngFor="let comment of race.horsesComments">
                <td><small>{{comment.updatedAt | date:'h:mm a'}}</small></td>
                <td><small>{{comment.subject.knownAs}}</small></td>
                <td><small>{{comment.rpcComments === null ? 'Comment' : 'RPC'}}</small></td>
                <td>
                  <small>
                    {{comment.comment}}
                    <span *ngFor="let rpc of comment.rpcComments; last as last">
                      {{rpc.racePerformanceComment}}
                      <span *ngIf="!last">, </span>
                    </span>
                  </small>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.horsesComments.length">
              <tr class="text-center mt-2">
                <td colspan="4">No comments for horses in this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Race Enquiries -->
      <h5>Race Enquiries</h5>
      <div class="row no-break-inside">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col-2">Offender</th>
                <th scope="col-2">Outcome</th>
                <th scope="col-6">Comment</th>
                <th scope="col-2">Penalties</th>
              </tr>
            </thead>
            <tbody *ngIf="race.enquiries.length">
              <tr *ngFor="let entry of race.enquiries">
                <td><small>{{entry.updatedAt}}</small></td>
                <td><small>{{entry.offence.offence}} {{entry.offence.subOffence}}</small></td>
                <td><small>{{entry.ptcComment}}</small></td>
                <td><small>{{entry.breachStatus | titlecase}}<br *ngIf="entry.breachStatus === 'in breach'"><app-enquiry-outcomes [enquiry]="entry"></app-enquiry-outcomes></small></td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.enquiries.length">
              <tr class="text-center mt-2">
                <td colspan="4">No enquiries for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Selected for Sampling -->
      <h5>Selected for Sampling</h5>
      <div class="row no-break-inside">
        <div class="col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">Horse</th>
                <th scope="col">Reason</th>
              </tr>
            </thead>
            <tbody *ngIf="race.selectedSampling.length">
              <tr *ngFor="let entry of race.selectedSampling">
                <td><small>{{entry.horseName}}</small></td>
                <td>
                  <small>
                    <span *ngFor="let item of entry.sampleRequestReasons; last as last">
                      {{item.reason}}
                      <span *ngIf="!last">, </span>
                    </span>
                  </small>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!race.selectedSampling.length">
              <tr class="text-center mt-2">
                <td colspan="2">No horse selected for sampling for this race</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
